import React from 'react'
import { Box, Heading, Divider, HStack, Tag } from '@chakra-ui/react'

import Format from 'date-fns/format'

const PostIntroMetaData = ({ title, date, readTime }) => (
  <>
    <Heading my={8}>{title}</Heading>
    <Box>
      {Format(new Date(date), 'dd MMM, yyyy')} {readTime && `• ${readTime}min`}
    </Box>
    <Divider />
  </>
)

const PostOutroMetaData = ({ title, tags, shareUrl }) => {
  const sharingTitle = `${title} | @onkbear`
  return (
    <div>
      <Divider />
      <HStack>
        {tags &&
          tags.length > 0 &&
          tags.map((tag) => <Tag key={tag}>{tag.title}</Tag>)}
      </HStack>
    </div>
  )
}

export { PostIntroMetaData, PostOutroMetaData }
