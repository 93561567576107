import React from 'react'
import { Box } from '@chakra-ui/react'
import Image from '../../Common/Image'

const HeaderImage = ({ headerImage }) => (
  <Box>
    {headerImage && <Image image={headerImage} alt={headerImage.title} />}
    {headerImage && (
      <Box textAlign="center">
        <div dangerouslySetInnerHTML={{ __html: headerImage.description }} />
      </Box>
    )}
  </Box>
)

export default HeaderImage
