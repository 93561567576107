import React from 'react'
import {
  Heading,
  Link,
  Box,
  UnorderedList,
  OrderedList,
  ListItem,
  Code,
} from '@chakra-ui/react'

const H1 = ({ children }) => {
  return (
    <Heading as="h1" size="xl">
      {children}
    </Heading>
  )
}

const H2 = ({ children }) => {
  return (
    <Heading as="h2" size="lg">
      {children}
    </Heading>
  )
}

const H3 = ({ children }) => {
  return (
    <Heading as="h3" size="md">
      {children}
    </Heading>
  )
}

const H4 = ({ children }) => {
  return (
    <Heading as="h4" size="sm">
      {children}
    </Heading>
  )
}

const Body = ({ children }) => {
  return <Box my="1.5em">{children}</Box>
}

const A = ({ children }) => {
  return <Link>{children}</Link>
}

export { H1, H2, H3, H4, Body, A }
