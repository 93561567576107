import React from 'react'
import Gist from 'react-gist'
import { Box } from '@chakra-ui/react'

/**
 * Blocks/Gist doesn't take className prop :(
 */
const WrappedGist = ({ id }) => (
  <Box className="gist">
    <Gist id={id} />
  </Box>
)

export default WrappedGist
