import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import {
  Box,
  Container,
  Code,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@chakra-ui/react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
  PostIntroMetaData,
  PostOutroMetaData,
} from '../components/Blog/post/PostMetaData'

import FigureImage from '../components/Blog/post/FigureImage'
import Gist from '../components/Blog/post/Gist'
import HeaderImage from '../components/Blog/post/HeaderImage'

import { H1, H2, H3, H4, Body, A } from '../components/Blog/post/Typography'

const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  ul: UnorderedList,
  ol: OrderedList,
  li: ListItem,
  p: Body,
  code: Code,
  anchor: A,
}
const shortCodes = {
  FigureImage,
  Gist,
}

// TODO
const tags = []

const BlogPostTemplate = ({
  data: { contentfulBlogPost: post },
  location,
  __mdxScope,
}) => {
  const seoMetaData = {
    title: post.title,
    description: post.content.childMdx.excerpt,
    socialLogo: post.headerImage && post.headerImage,
    keywords: tags,
  }

  return (
    <MDXProvider components={{ ...components, ...shortCodes }}>
      <Layout>
        <SEO
          title={seoMetaData.title}
          lang="ja"
          description={seoMetaData.description}
          imageUrl={seoMetaData.socialLogo.fixed.src}
        />
        <Container maxW="4xl" py={8}>
          {post.headerImage && <HeaderImage headerImage={post.headerImage} />}
        </Container>
        <Container maxW="3xl">
          <Box>
            <PostIntroMetaData
              title={post.title}
              date={post.firstPublishedAt}
              readTime={post.content.childMdx.timeToRead}
            />
          </Box>
          <Box>
            <Box>
              <MDXRenderer scope={__mdxScope}>
                {post.content.childMdx.body}
              </MDXRenderer>
            </Box>
            <PostOutroMetaData
              title={post.title}
              tags={post.tags}
              shareUrl={location.href}
            />
          </Box>
        </Container>
      </Layout>
    </MDXProvider>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      id
      title
      headerImage {
        file {
          url
        }
        title
        description
        fixed(width: 1280, quality: null) {
          src
        }
        fluid(maxWidth: 640) {
          ...GatsbyContentfulFluid
        }
        gatsbyImageData
      }
      slug
      firstPublishedAt
      content {
        childMdx {
          body
          timeToRead
          excerpt
        }
      }
      tags {
        title
      }
    }
  }
`
