import React from 'react'
import { Box, Image } from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'

/**
 * NOT used anywhere in the codebase, this component is utilised in the
 * blog post MDX content that is stored in Contentful
 */
const FigureImage = ({ src, alt, caption }) => (
  <Box as="figure" textAlign="center" pt="2em">
    <Image src={src} alt={alt} loading="lazy" />
    {caption && (
      <Box as="figcaption" textAlign="center" m="0 auto">
        <ReactMarkdown source={caption} />
      </Box>
    )}
  </Box>
)

export default FigureImage
